.rc-footer {
  position: relative;
  clear: both;
  color: rgba(255, 255, 255, 0.4);
  font-size: 14px;
  line-height: 1.5;
  background-color: #000;
}
.rc-footer a {
  transition: all 0.3s;
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
}
.rc-footer a:hover {
  color: #40a9ff;
}
.rc-footer-container {
  width: 100%;
  max-width: 1200px;
  padding: 80px 0 20px;
  margin: auto;
}
.rc-footer-columns {
  display: flex;
  justify-content: space-around;
}
.rc-footer-column {
  margin-bottom: 60px;
}
.rc-footer-column h2 {
  position: relative;
  margin: 0 auto 24px;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
}
.rc-footer-column-icon {
  margin-right: 0.5em;
  width: 22px;
  display: inline-block;
  vertical-align: middle;
  top: -1px;
  position: relative;
  text-align: center;
}
.rc-footer-column-icon > span,
.rc-footer-column-icon > svg,
.rc-footer-column-icon img {
  width: 100%;
  display: block;
}
.rc-footer-item {
  margin: 12px 0;
}
.rc-footer-item-icon {
  margin-right: 0.4em;
  width: 16px;
  display: inline-block;
  vertical-align: middle;
  top: -1px;
  position: relative;
  text-align: center;
}
.rc-footer-item-icon > span,
.rc-footer-item-icon > svg,
.rc-footer-item-icon img {
  width: 100%;
  display: block;
}
.rc-footer-item-separator {
  margin: 0 0.3em;
}
.rc-footer-bottom-container {
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  width: 100%;
  max-width: 1200px;
  text-align: center;
  margin: 0 auto;
  padding: 16px 0;
  line-height: 32px;
  font-size: 16px;
}
.rc-footer-light {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.85);
}
.rc-footer-light h2,
.rc-footer-light a {
  color: rgba(0, 0, 0, 0.85);
}
.rc-footer-light .rc-footer-bottom-container {
  border-top-color: #e8e8e8;
}
.rc-footer-light .rc-footer-item-separator,
.rc-footer-light .rc-footer-item-description {
  color: rgba(0, 0, 0, 0.45);
}
@media only screen and (max-width: 767.99px) {
  .rc-footer {
    text-align: center;
  }
  .rc-footer-container {
    padding: 40px 0;
  }
  .rc-footer-columns {
    display: block;
  }
  .rc-footer-column {
    display: block;
    margin-bottom: 40px;
  }
  .rc-footer-column:last-child {
    margin-bottom: 0;
  }
}

/*

Atom One Dark by Daniel Gamage
Original One Dark Syntax theme from https://github.com/atom/one-dark-syntax

base:    #282c34
mono-1:  #abb2bf
mono-2:  #818896
mono-3:  #5c6370
hue-1:   #56b6c2
hue-2:   #61aeee
hue-3:   #c678dd
hue-4:   #98c379
hue-5:   #e06c75
hue-5-2: #be5046
hue-6:   #d19a66
hue-6-2: #e6c07b

*/

.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  color: #abb2bf;
  background: #282c34;
}

.hljs-comment,
.hljs-quote {
  color: #5c6370;
  font-style: italic;
}

.hljs-doctag,
.hljs-keyword,
.hljs-formula {
  color: #c678dd;
}

.hljs-section,
.hljs-name,
.hljs-selector-tag,
.hljs-deletion,
.hljs-subst {
  color: #e06c75;
}

.hljs-literal {
  color: #56b6c2;
}

.hljs-string,
.hljs-regexp,
.hljs-addition,
.hljs-attribute,
.hljs-meta-string {
  color: #98c379;
}

.hljs-built_in,
.hljs-class .hljs-title {
  color: #e6c07b;
}

.hljs-attr,
.hljs-variable,
.hljs-template-variable,
.hljs-type,
.hljs-selector-class,
.hljs-selector-attr,
.hljs-selector-pseudo,
.hljs-number {
  color: #d19a66;
}

.hljs-symbol,
.hljs-bullet,
.hljs-link,
.hljs-meta,
.hljs-selector-id,
.hljs-title {
  color: #61aeee;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

.hljs-link {
  text-decoration: underline;
}

